import axios from "axios";
import { Device } from "../models/Device";
import { url as apiurl } from "../auth/constants.prod";

export class Devices {
    public async getDevices(token: string, filter?: string, next?: string): Promise<{ data: Device[], odataLink: string }> {
        let url = apiurl + "/api/v1/Device";
        let params = [];
        if (filter) {
            params.push(`filter=${filter}`);
        }
        if (next) {
            params.push(`next=${next}`);
        }
        if (params.length > 0) {
            url += `?${params.join('&')}`;
        }
        const response = await axios.get(url, {headers: {Authorization: `Bearer ${token}`}});
        return {
            data: response.data.data,
            odataLink: response.data.odataLink
        };
    }
}
import axios, { Axios, AxiosResponse }  from "axios";
import { url as apiurl } from "../auth/constants.prod";
import { Issue } from "../models/Issue";

/**
 * ReportBug service used to interact with the API Git endpoint.
 *
 * @class ReportBug
 */
export class ReportBug {
    private baseURL = apiurl + "/api/v1/Git/issue";

    /**
     * Report a bug by sending the issue to the API to be registered on Github repository.
     *
     * @param {string} token - The token used to authenticate the request.
     * @param {Issue} issue - An issue containing the title and body of the bug report.
     * @returns {Promise<number>} the status code of the request.
     */
    public async reportBug(token: string, issue: Issue): Promise<AxiosResponse> {
        const response = await axios.post(`${this.baseURL}`, issue, {headers: {Authorization: `Bearer ${token}`}});
        return response;
    }
}
import axios, {Axios, AxiosResponse} from "axios";
import {url as apiurl} from "../auth/constants.prod";
import {CombinedGroup} from "../models/CombinedGroup";

export class Groups {
    private baseURL = apiurl + "/api/v1/Group";

    public async getGroups(token: string): Promise<CombinedGroup[]> {
        const response = await axios.get(`${this.baseURL}/combined`, {headers: {Authorization: `Bearer ${token}`}});
        return response.data;
    }
    
    public async getDeviceGroups(token: string, deviceId: string | number): Promise<CombinedGroup[]> {
        const response = await axios.get(`${this.baseURL}/Device/${deviceId}`, {headers: {Authorization: `Bearer ${token}`}});
        if (response.data.length <= 0) {
            return [];
        }
        return response.data;
    }

    public async addDevicesToGroups(token: string, selectedGroupIds: string[], selectedDevice: string[]): Promise<number[]> {
        const responseArray: number[] = [];
        if (selectedGroupIds.length === 0) {
            console.log('No groups selected.');
        } else {
            for (const groupId of selectedGroupIds) {
                const url = `${this.baseURL}/${groupId}`;
                try {
                    const response = await axios.patch(url, selectedDevice, {headers: {Authorization: `Bearer ${token}`}});
                    responseArray.push(response.status);
                    console.log(`${response.status}: Selected devices have been added to ${groupId} group.`);
                } catch (error) {
                    if (axios.isAxiosError(error) && error.response) {
                        responseArray.push(error.response.status);
                        console.warn(`${error.response.status}: Failed to add selected devices to ${groupId} group.`);
                    } 
            }
        }
    }
        return responseArray;
    }

    public async deleteDevicesFromGroups(token: string, selectedGroupIds: string[], selectedDevice: string[]): Promise<number[]> {
        const responseArray: number[] = [];
        if (selectedGroupIds.length === 0) {
            console.log('No groups selected.');
        } else {
            for (const groupId of selectedGroupIds) {
                const url = `${this.baseURL}/${groupId}`;
                try {
                    const response = await axios.delete(url, {
                        data: selectedDevice,
                        headers: {Authorization: `Bearer ${token}`}
                    });
                    responseArray.push(response.status);
                    console.log(`${response.status}: Selected devices have been removed to ${groupId} group.`);
                } catch (error) {
                    if (axios.isAxiosError(error) && error.response) {
                        responseArray.push(error.response.status);
                        console.warn(`${error.response.status}: Failed to remove selected devices to ${groupId} group.`);
                    } else {
                        throw error;
                    }
                }
            }
        }
        return responseArray;
    }
}